import Vue from 'vue'
import App from './App.vue'
import router from "./scripts/router";
import store from "./scripts/store";
import vuetify from './scripts/vuetify';
import { auth } from "./scripts/fb.js";
import "./scripts/global"

Vue.config.productionTip = false

let app;

auth.onAuthStateChanged(initializeApp);

async function initializeApp(user) {
  if (!user) {
    await auth.signInAnonymously();
    return
  }
  await store.dispatch("fetchUser", user);
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
}