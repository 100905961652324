<template>
  <v-form v-model="valid">
    <v-container style="max-width: 600px">
      <v-row justify="center">
        <v-col>
          <v-textarea
            outlined
            :label="label"
            :rules="rules"
            v-model="value"
            :disabled="busy"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-slide-x-transition mode="out-in">
          <v-btn
            v-if="!sent"
            color="primary"
            :disabled="!valid"
            :loading="busy"
            @click="submit"
          >
            <span class="font-weight-bold">Enviar</span>
          </v-btn>

          <v-btn v-else color="primary" text @click="reset">
            enviar outro?
          </v-btn>
        </v-slide-x-transition>
      </v-row>
    </v-container>
    <v-snackbar color="success" v-model="success">
      <div class="d-flex">
        Enviado com sucesso!
        <v-spacer></v-spacer>
        <v-btn x-small icon @click="success = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
    <v-snackbar color="error" v-model="error">
      <div class="d-flex">
        Erro ao enviar. Cheque sua conexão e tente novamente.
        <v-spacer></v-spacer>
        <v-btn x-small icon @click="error = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </v-form>
</template>
<script>
import { sendComment } from "../../scripts/data/track";
export default {
  name: "comment-form",
  props: ["label"],
  data: () => ({
    value: "",
    valid: true,
    busy: false,
    sent: false,
    success: false,
    error: false,
    rules: [(v) => !!v || "Campo necessário"],
  }),
  methods: {
    async submit() {
      if (this.busy) return;
      if (!this.valid) return;
      try {
        this.busy = true;
        this.error = false;
        this.sent = false;
        await sendComment(this.value);
        this.sent = true;
        this.success = true;
      } catch (error) {
        console.log(error);
        this.error = true;
        this.sent = false;
        this.busy = false;
      }
    },
    reset() {
      this.value = "";
      this.busy = false;
      this.sent = false;
      this.error = false;
    },
  },
};
</script>