<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="0"
        color="primary"
        x-small
        v-bind="attrs"
        v-on="on"
        style="margin-top: 20px"
        rounded
      >
        Tutorial
      </v-btn>
    </template>
    <draggable-tutorial-mobile v-if="$vuetify.breakpoint.mobile" />
    <draggable-tutorial-desktop v-else />
    <v-btn icon dark absolute right top @click="dialog = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-dialog>
</template>
<script>
import DraggableTutorialDesktop from "../draggable/DraggableTutorialDesktop.vue";
import DraggableTutorialMobile from "../draggable/DraggableTutorialMobile.vue";
export default {
  components: { DraggableTutorialDesktop, DraggableTutorialMobile },
  name: "draggable-help",
  props: ["showOnInit"],
  data: () => ({ dialog: false }),
  mounted() {
    if (this.showOnInit) this.dialog = true;
  },
};
</script>
