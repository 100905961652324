<template>
  <v-tabs vertical v-model="tab">
    <v-tab v-for="pillarStat in pillarStats" :key="pillarStat.key">
      {{ pillarStat.key.replace("_"," ") }}
    </v-tab>
    <v-tab-item v-for="(pillarStat, i) in pillarStats" :key="`pillar-${i}`">
      <v-container>
        <v-row>
          <v-col
            v-for="(pillar, key) in pillarStat.value"
            :key="`pillarstat-${key}`"
          >
            <v-card class="mx-auto" elevation="4" color="secondary">
              <v-card-title>
                {{ `${getLabel(key)}` }}
              </v-card-title>
              <v-container>
                <div>
                  <v-icon color="success" class="mr-2">mdi-plus-circle</v-icon>
                  {{
                    `${percentage(pillar.positivo, pillar.total)} (${
                      pillar.positivo
                    })`
                  }}
                </div>
                <div>
                  <v-icon color="error" class="mr-2">mdi-minus-circle</v-icon>
                  {{
                    `${percentage(pillar.negativo, pillar.total)} (${
                      pillar.negativo
                    })`
                  }}
                </div>
              </v-container>
              <div
                v-for="(stageValue, stageKey) in pillar.stages"
                :key="stageKey"
              >
                <v-divider></v-divider>
                <v-card-subtitle>
                  {{ `${getLabel(stageKey)}` }}
                </v-card-subtitle>
                <v-container>
                  <div>
                    <v-icon color="success" class="mr-2"
                      >mdi-plus-circle</v-icon
                    >
                    {{
                      `${percentage(stageValue.positivo, stageValue.total)} (${
                        stageValue.positivo
                      })`
                    }}
                  </div>
                  <div>
                    <v-icon color="error" class="mr-2">mdi-minus-circle</v-icon>
                    {{
                      `${percentage(stageValue.negativo, stageValue.total)} (${
                        stageValue.negativo
                      })`
                    }}
                  </div>
                </v-container>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
  </v-tabs>
</template>
<script>
import { statMixin } from "../../mixins/statUtil";
export default {
  props: ["stats"],
  data: () => ({
    tab: null,
  }),
  mixins: [statMixin],
  computed: {
    pillarStats: function () {
      let pillars = [];

      let pillar = { key: "geral", value: {} };
      Object.keys(this.stats).forEach((key) => {
        let stat = this.stats[key];

        if (!pillar.value[stat.pillar])
          pillar.value[stat.pillar] = {
            total: 0,
            positivo: 0,
            negativo: 0,
            stages: {},
          };

        pillar.value[stat.pillar]["total"] += stat.total;

        if (!pillar.value[stat.pillar][stat.value])
          pillar.value[stat.pillar][stat.value] = 0;
        pillar.value[stat.pillar][stat.value] += stat.total;

        if (!pillar.value[stat.pillar].stages[stat.stage])
          pillar.value[stat.pillar].stages[stat.stage] = {
            total: 0,
            positivo: 0,
            negativo: 0,
          };
        pillar.value[stat.pillar].stages[stat.stage]["total"] += stat.total;
        pillar.value[stat.pillar].stages[stat.stage][stat.value] += stat.total;
      });
      pillars.push(pillar);

      let contextKeys = [];
      Object.keys(this.stats).forEach((key) => {
        let stat = this.stats[key];
        Object.keys(stat.context).forEach((ctxKey) => {
          if (contextKeys.indexOf(ctxKey) < 0) contextKeys.push(ctxKey);
        });
      });

      contextKeys.forEach((ctxKey) => {
        let ctxPillar = { key: ctxKey, value: {} };
        Object.keys(this.stats).forEach((key) => {
          let stat = this.stats[key];

          if (!ctxPillar.value[stat.pillar])
            ctxPillar.value[stat.pillar] = {
              total: 0,
              positivo: 0,
              negativo: 0,
              stages: {},
            };

          if (stat.context[ctxKey]) {
            ctxPillar.value[stat.pillar]["total"] += stat.context[ctxKey];

            if (!ctxPillar.value[stat.pillar][stat.value])
              ctxPillar.value[stat.pillar][stat.value] = 0;
            ctxPillar.value[stat.pillar][stat.value] += stat.context[ctxKey];

            if (!ctxPillar.value[stat.pillar].stages[stat.stage])
              ctxPillar.value[stat.pillar].stages[stat.stage] = {
                total: 0,
                positivo: 0,
                negativo: 0,
              };
            ctxPillar.value[stat.pillar].stages[stat.stage]["total"] +=
              stat.context[ctxKey];
            ctxPillar.value[stat.pillar].stages[stat.stage][stat.value] +=
              stat.context[ctxKey];
          }
        });
        pillars.push(ctxPillar);
      });

      return pillars;
    },
  },
};
</script>