<template>
  <div class="canvas-wrapper">
    <div class="canvas">
      <div class="folder">
        <img
          class="aba"
          :src="require('../../assets/images/TUTORIAL/mobile/ABA.svg')"
        />
        <div class="aba-body" />
      </div>
      <div class="postit-container">
        <div class="postit"></div>
      </div>
      <img
        class="click"
        :src="require('../../assets/images/TUTORIAL/mobile/MAO.svg')"
      />
      <img
        class="mobile"
        :src="require('../../assets/images/TUTORIAL/mobile/MOBILE.svg')"
      />

      <span class="description">
        Arraste apenas <b>duas respostas</b> para a barra "ESCOLHAS".
      </span>
    </div>
  </div>
</template>
<script>
import anime from "animejs/lib/anime.es.js";
export default {
  name: "draggable-tutorial",
  mounted() {
    this.enter();
  },
  methods: {
    enter() {
      var click = document.querySelectorAll(".click");
      var postitContainer = document.querySelectorAll(".postit-container");
      var postit = document.querySelectorAll(".postit");
      var folder = document.querySelectorAll(".folder");
      var timeline = anime.timeline({ loop: true });
      timeline.add({
        targets: click,
        duration: 1400,
        easing: "easeOutCubic",
        left: ["60%", "46%"],
        top: ["40%", "35%"],
      });
      timeline.add({
        targets: click,
        duration: 400,
        easing: "easeInOutQuint",
        width: ["14%", "12%"],
      });
      timeline.add({
        targets: click,
        duration: 1000,
        easing: "easeOutCubic",
        top: ["35%", "74%"],
      });
      timeline.add(
        {
          targets: postitContainer,
          duration: 1000,
          easing: "easeOutCubic",
          top: ["35%", "74%"],
        },
        "-=1000"
      );
      timeline.add({
        targets: click,
        duration: 400,
        easing: "easeInOutQuint",
        width: ["12%", "14%"],
      });
      timeline.add(
        {
          targets: folder,
          duration: 1000,
          height: ["4%", "14%"],
          easing: "easeInOutSine",
        },
        "-=1600"
      );
      timeline.add({
        targets: click,
        duration: 1400,
        easing: "easeOutCubic",
        left: ["46%", "60%"],
        top: ["74%", "40%"],
      });
      timeline.add(
        {
          targets: folder,
          duration: 1000,
          height: ["14%", "4%"],
          easing: "easeInOutSine",
        },
        "-=1000"
      );
      timeline.add(
        {
          targets: postitContainer,
          duration: 1000,
          top: ["74%", "79%"],
          easing: "easeInOutSine",
        },
        "-=1000"
      );
      timeline.add(
        {
          targets: postit,
          duration: 1000,
          paddingBottom: ["100%", "0%"],
          easing: "easeInOutSine",
        },
        "-=1000"
      );
      timeline.add({
        targets: postitContainer,
        duration: 10,
        top: ["60%", "35%"],
        easing: "easeInOutSine",
      });
      timeline.add({
        targets: postit,
        duration: 1000,
        paddingBottom: ["0%", "100%"],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.canvas-wrapper {
  width: 100%;
  height: 100%;
  background: rgba(black, 0.9);
  box-sizing: border-box;
  resize: horizontal;
  display: grid;
  place-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.canvas {
  position: relative;
  width: 100%;
  padding-bottom: 177.78%;
}
.folder {
  position: absolute;
  top: 78%;
  left: 50%;
  width: 51%;
  height: 8%;
  transform: translate(-50%, -100%);
}
.aba {
  margin-bottom: -14%;
}
.aba-body {
  height: 100%;
  background: var(--v-primary-lighten2);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.click {
  position: absolute;
  width: 14%;
  transform: translate(-50%, -50%);
}
.mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
}
.postit-container {
  position: absolute;
  top: 35%;
  left: 42%;
  width: 16%;
  box-sizing: border-box;
  resize: horizontal;
  transform: translate(-50%, -50%);
}
.postit {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background: var(--v-secondary-base);
}
.description {
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}
</style>