import { db } from "../fb.js";

export async function getStats(requestId) {
  try {
    var statSnap = await db
      .collection("requestFeedback")
      .doc(requestId)
      .collection("stats")
      .get();
    var commentSnap = await db
      .collection("requestFeedback")
      .doc(requestId)
      .collection("comments")
      .get();
    return {
      stats: handleStatGetResponde(statSnap),
      comments: handleCommentGetResponde(commentSnap),
    };
  } catch (error) {
    console.log(error);
  }
}
function handleStatGetResponde(snap) {
  var result = {};
  snap.docs.forEach((doc) => {
    result[doc.id] = doc.data();
  });
  return result;
}
function handleCommentGetResponde(snap) {
  var result = [];
  snap.docs.forEach((doc) => {
    result.push(doc.data());
  });
  return result;
}
