<template>
  <span :class="['brand', size, `${color}--text`]">FLÖWR</span>
</template>
<script>
export default {
  name: "brand",
  props: {
    size: { default: "md", type: String },
    color: { default: "primary", type: String },
  },
};
</script>
<style scoped>
.brand {
  font-weight: bolder;
}

.brand.sm {
  font-size: 1rem;
}
.brand.md {
  font-size: 1.2rem;
}
.brand.lg {
  font-size: 1.6rem;
}
.brand.x-lg {
  font-size: 2.4rem;
}
</style>
