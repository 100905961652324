import { auth, googleAuthProvider } from "./fb.js";
import router from "./router";
import "firebase/auth"

export function signIn(user, successCallback, errorCallback) {
    auth.signInWithEmailAndPassword(user.email, user.password)
        .then((userCredential) => {
            successCallback(userCredential.user);
        }).catch((err) => {
            errorCallback(err);
        });
}

export function signInGoogle(successCallback, errorCallback) {
    auth.signInWithPopup(googleAuthProvider)
        .then((userCredential) => {
            successCallback(userCredential.user);
        }).catch((err) => {
            errorCallback(err);
        });
}

export function signOut() {
    auth.signOut()
        .then(() => {
            router.push("/auth")
        })
        .catch((err) => {
            console.log(err);
        });
}

export async function sendResetPasswordEmail(email, successCallback, errorCallback) {
    try {
        await auth.sendPasswordResetEmail(email)
        successCallback()
    } catch (error) {
        errorCallback(error)
    }
}

export async function signInAnonymous() {
    await auth.signInAnonymously();
}

export async function getUserRoles() {
    const { claims } = await auth.currentUser.getIdTokenResult()

    let roles = {};

    Object.keys(claims).forEach(key => {
        if (allRoles[key] != null) {
            roles[key] = {
                ...allRoles[key], active: claims[key]
            }
        }
    })
    return roles
}

const allRoles = {
    admin:
        { name: "Administrador" }
}