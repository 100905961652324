<template>
  <div v-if="!loading">
    <v-toolbar dense flat>
      <brand size="lg" class="mr-8" />
      <v-tabs v-model="tab">
        <v-tab>Geral</v-tab>
        <v-tab>Base</v-tab>
        <v-tab>Pilares</v-tab>
        <v-tab>Mais escolhidas</v-tab>
        <v-tab>Comentários</v-tab>
      </v-tabs>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <GeneralStats :stats="statData.stats.general" />
      </v-tab-item>
      <v-tab-item>
        <StatsTable :stats="statData.stats.choice" />
      </v-tab-item>
      <v-tab-item>
        <pillar-list :stats="statData.stats.choice"></pillar-list>
      </v-tab-item>
      <v-tab-item>
        <top-list
          :stats="statData.stats.choice"
          :general="statData.stats.general"
        ></top-list>
      </v-tab-item>
      <v-tab-item>
        <comment-list :comments="statData.comments"></comment-list>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { getStats } from "../scripts/data/stats";
import { getTrackRequestData } from "../scripts/data/track";
import GeneralStats from "../components/stats/GeneralStats";
import StatsTable from "../components/stats/StatsTable";
import CommentList from "../components/stats/CommentList.vue";
import PillarList from "../components/stats/PillarList.vue";
import TopList from "../components/stats/TopList.vue";
export default {
  components: {
    GeneralStats,
    StatsTable,
    CommentList,
    PillarList,
    TopList,
  },
  props: ["id"],
  data: () => ({
    tab: 0,
    loading: true,
    error: null,
    statData: null,
    requestData: null,
  }),
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      this.error = null;
      try {
        this.statData = await getStats(this.id);
        this.requestData = await getTrackRequestData(this.id);
        this.loading = false;
      } catch (err) {
        this.error = err;
        console.log(err);
      }
    },
  },
};
</script>
