var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'draggable',
    'draggable-card',
    _vm.$vuetify.breakpoint.mobile ? 'mobile' : 'desktop',
    _vm.dragging ? 'dragging' : 'idle',
    'enter-animation-target',
    'leave-animation-target' ],style:({
    webkitTransform: ("translate(" + (_vm.item.x) + "px, " + (_vm.item.y) + "px)"),
    transform: ("translate(" + (_vm.item.x) + "px, " + (_vm.item.y) + "px)"),
  }),attrs:{"id":_vm.item.id}},[_vm._v(" "+_vm._s(_vm.item.label)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }