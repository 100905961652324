var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4 export-btn-container",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.exportAndDownload}},[_vm._v("Exportar")])],1)],1),_c('v-data-table',{attrs:{"items":_vm.formatedData,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":true},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.pillar",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","outlined":_vm.pillarFilter == '',"color":_vm.getPillarColor(item.pillar)},on:{"click":function () {
            if (_vm.pillarFilter == '') { _vm.pillarFilter = item.pillar; }
            else { _vm.pillarFilter = ''; }
          }}},[_vm._v(" "+_vm._s(_vm.getLabel(item.pillar).toUpperCase())+" ")])]}},{key:"item.stage",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"dark":_vm.stageFilter != '',"outlined":_vm.stageFilter == ''},on:{"click":function () {
            if (_vm.stageFilter == '') { _vm.stageFilter = item.stage; }
            else { _vm.stageFilter = ''; }
          }}},[_vm._v(" "+_vm._s(_vm.getLabel(item.stage).toUpperCase())+" ")])]}},{key:"item.dimension",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"dark":_vm.dimensionFilter != '',"outlined":_vm.dimensionFilter == ''},on:{"click":function () {
            if (_vm.dimensionFilter == '') { _vm.dimensionFilter = item.dimension; }
            else { _vm.dimensionFilter = ''; }
          }}},[_vm._v(" "+_vm._s(_vm.getLabel(item.dimension).toUpperCase())+" ")])]}},{key:"item.value",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"dark":_vm.valueFilter != '',"outlined":_vm.valueFilter == '',"color":_vm.getValueColor(item.value)},on:{"click":function () {
            if (_vm.valueFilter == '') { _vm.valueFilter = item.value; }
            else { _vm.valueFilter = ''; }
          }}},[_vm._v(" "+_vm._s(_vm.getLabel(item.value).toUpperCase())+" ")])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{attrs:{"colspan":"6"}}),_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.total))]),_vm._l((_vm.contextFilterList),function(context){return _c('td',{key:context,staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.contextTotals[context])+" ")])})],2)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }