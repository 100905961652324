<template>
  <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="submit">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" class="pa-1">
          <v-text-field
            v-model="email"
            :rules="[required(email), emailRule(email)]"
            placeholder="Email"
            :disabled="loading"
            prepend-icon="mdi-at"
            required
            filled
            dense
          ></v-text-field>
        </v-col>
        <v-col md="4" cols="12" class="pa-1 my-4">
          <v-btn color="primary" block type="submit" :disabled="loading">
            <v-icon left dark> mdi-email-fast-outline </v-icon>
            Enviar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar color="success" text v-model="success">
      Email enviado com sucesso.
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="success = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar color="error" text v-model="error">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="error = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>
<script>
import { sendResetPasswordEmail } from "../../scripts/auth";

export default {
  data: () => ({
    valid: false,
    email: "",
    loading: false,
    error: false,
    errorMessage: null,
    success: false,
  }),
  methods: {
    async submit() {
      this.validate();
      if (!this.valid || this.loading) return;
      this.loading = true;
      this.errorMessage = null;
      this.error = false;
      await sendResetPasswordEmail(
        this.email.trim(),
        this.successCallback,
        this.errorCallback
      );
    },
    successCallback() {
      this.success = true;
      this.loading = false;
    },
    errorCallback(error) {
      this.loading = false;
      this.errorMessage = error;
      this.error = true;
    },
    required(v) {
      return !!v || "Campo obrigatório";
    },
    emailRule(v) {
      return /.+@.+/.test(v) || "Email inválido";
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
