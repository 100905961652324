<template>
  <v-container fill-height align-start fluid pa-0 ma-0>
    <div
      id="shelf"
      class="shelf"
      :class="[
        dragging ? 'open' : 'closed',
        $vuetify.breakpoint.mobile ? 'mobile' : 'desktop',
        $vuetify.breakpoint.name,
      ]"
    >
      <transition-group
        appear
        v-on:enter="enter"
        v-bind:css="false"
        tag="div"
        class="dropzone"
      >
        <draggable-item
          v-for="item in items"
          :key="item.id"
          :item="item"
          :dragging="item.id == dragging"
        />
      </transition-group>
    </div>
    <div
      id="folder"
      class="folder-container"
      :class="{ mobile: $vuetify.breakpoint.mobile }"
      @mouseleave="mouseLeave"
    >
      <div
        style="margin-left: 1rem; width: 15rem; margin-bottom: -2px"
        class="d-flex"
      >
        <v-img
          :src="require('../../assets/images/folderTab.png')"
          contain
          @mouseenter="mouseEnter"
          @click="mouseEnter"
        />
        <draggable-help :show-on-init="tutorial" />
      </div>
      <div
        @mouseenter="mouseEnter"
        @click="mouseEnter"
        :class="['folder', folder ? 'open' : 'closed']"
      >
        <v-container
          :px-10="!$vuetify.breakpoint.mobile"
          :px-2="$vuetify.breakpoint.mobile"
        >
          <transition-group
            appear
            v-on:enter="enter"
            v-bind:css="false"
            tag="div"
            id="folder"
            class="dropzone dropzone-container"
            :class="[
              $vuetify.breakpoint.mobile
                ? 'justify-center align-center'
                : 'align-center',
            ]"
          >
            <draggable-item
              v-for="item in selected"
              :key="item.id"
              :item="item"
              :dragging="item.id == dragging"
            />
            <draggable-item-placeholder
              v-for="i in 2 - selected.length"
              :key="`placeholder${i}`"
            ></draggable-item-placeholder>
          </transition-group>
        </v-container>
      </div>
    </div>
  </v-container>
</template>
<script>
import anime from "animejs/lib/anime.es.js";
import interact from "interactjs";
import { setCurrentTrackData } from "../../scripts/data/track";
import store from "../../scripts/store";
import DraggableItem from "../draggable/DraggableItem.vue";
import DraggableItemPlaceholder from "../draggable/DraggableItemPlaceholder.vue";
import DraggableHelp from "./DraggableHelp.vue";
import stages from "../../assets/stages";
export default {
  components: { DraggableItem, DraggableItemPlaceholder, DraggableHelp },
  name: "draggable",
  props: ["tutorial", "stage"],
  data: () => ({
    items: [],
    selected: [],
    dragging: null,
    folder: false,
  }),
  computed: {
    stageData: function () {
      return stages[this.stage];
    },
    dropzoneOptions: function () {
      return {
        accept: ".draggable",
        overlap: 0.2,
        ondrop: this.ondrop,
      };
    },
    draggableOptions: function () {
      return {
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: ".track",
            endOnly: true,
          }),
        ],
        listeners: {
          move: this.dragMoveListener,
          start: this.dragStartListener,
          end: this.dragEndListener,
        },
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setInitData();
      interact("#shelf").dropzone({
        accept: ".draggable",
        overlap: 0.4,
        ondrop: this.ondrop,
      });
      interact("#folder").dropzone({
        accept: ".draggable",
        overlap: 0.1,
        ondrop: this.ondrop,
      });
      interact(".draggable").draggable(this.draggableOptions);
      window.dragMoveListener = this.dragMoveListener;
    },
    setInitData() {
      this.selected = [];
      var storedData = store.getters.currentTrackRecord?.data[this.stage];

      if (storedData && storedData.length) {
        this.selected = storedData.map((element) => ({
          ...element,
          x: 0,
          y: 0,
        }));
      }
      this.items = [];
      var all = JSON.parse(JSON.stringify(this.shuffle(this.stageData.items)));
      this.items = all
        .filter((el) => !this.selected.find((sel) => sel.id == el.id))
        .map((element) => ({
          ...element,
          x: 0,
          y: 0,
        }));
      this.onValueUpdate();
    },
    ondrop(event) {
      var itemId = event.relatedTarget.id;
      var containerId = event.target.id;
      if (itemId && !containerId) containerId = "shelf";
      this.moveItem(itemId, containerId);
      this.dragEndListener();
    },
    moveItem(itemId, containerId) {
      var from = [];
      var to = [];
      if (containerId == "folder") {
        from = this.items;
        to = this.selected;
      } else if (containerId == "shelf") {
        from = this.selected;
        to = this.items;
      }
      var item = from.find((el) => el.id == itemId);
      if (!item) return;
      from.splice(from.indexOf(item), 1);
      to.push(item);
      this.resetPosition(item);
      if (this.selected.length > 2) {
        this.moveItem(this.selected[0].id, "shelf");
      }
      this.onValueUpdate();
    },
    resetPosition(item) {
      item.x = 0;
      item.y = 0;
    },
    mouseEnter() {
      if (!this.folder) this.folder = true;
    },
    mouseLeave() {
      if (this.folder && !this.dragging) this.folder = false;
    },
    onValueUpdate() {
      var sendData = [];
      this.selected.forEach((item) => {
        var sendItem = JSON.parse(JSON.stringify(item));
        delete sendItem.x;
        delete sendItem.y;
        sendData.push(sendItem);
      });
      setCurrentTrackData({
        prop: this.stage,
        value: sendData,
        valid: this.selected.length == 2,
      });
      this.$emit("on-event", { type: "onValueUpdate" });
    },
    dragStartListener(event) {
      this.dragging = event.target.id;
      this.folder = true;
    },
    dragEndListener() {
      var item = this.getItem(this.dragging);
      if (item) this.resetPosition(item);
      this.dragging = null;
      this.folder = false;
    },
    dragMoveListener(event) {
      var target = this.getItem(event.target.id);
      if (target) {
        target.x = (target.x || 0) + event.dx;
        target.y = (target.y || 0) + event.dy;
      }
    },
    getItem(id) {
      var item = this.items.find((element) => element.id == id);
      if (!item) item = this.selected.find((element) => element.id == id);
      return item;
    },
    enter(el, done) {
      anime({
        targets: el,
        duration: 1200,
        opacity: [0, 1],
        complete: done,
      });
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
  },
};
</script>
<style lang="scss" scoped>
.shelf {
  height: 80%;
  border-radius: 10px;
  width: 100%;
}
.shelf.sm {
  padding-bottom: 80px;
}
.shelf.open {
  transition: background-color 1.2s;
  background-color: rgba(black, 0.05);
}
.shelf.open.desktop {
  background-color: rgba(black, 0.1);
}
.shelf.closed {
  transition: background-color 400ms;
  background-color: rgba(black, 0);
}
.shelf.closed.desktop {
  background-color: rgba(black, 0.05);
}
.shelf.xl,
.shelf.lg {
  margin-right: 40px;
  margin-left: 40px;
}
.dropzone {
  display: grid;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}
.shelf .dropzone {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  place-items: center;
}
.shelf.xs .dropzone,
.shelf.sm .dropzone {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.folder-container .dropzone {
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  min-height: 240px;
}
.folder-container.mobile .dropzone {
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  min-height: 140px;
}
.folder-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.folder-container.mobile {
  position: fixed;
}
.folder {
  background: var(--v-primary-lighten2);
  padding-bottom: 2vh;
  padding-top: 2vh;
}
.folder.closed {
  max-height: 5vh;
  transition: max-height 1200ms;
  overflow: hidden;
}
.folder.open {
  max-height: 800px;
  transition: max-height 400ms;
}
</style>
