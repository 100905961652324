<template>
  <div
    class="track-container"
    :class="[$vuetify.breakpoint.name]"
    v-if="!isLoading"
  >
    <Header
      :header="nodes[currentNode].header"
      :disable-next-step="busy || hasInvalid"
      :disable-previous-step="busy"
      @on-event="onEvent"
    />
    <animation
      @enter-stop="changeBusyState(false)"
      @leave-enter="changeBusyState(true)"
    >
      <div
        :key="currentNode"
        class="track enter-animation-target leave-animation-target text-center"
        ref="track"
      >
        <node-tree
          v-for="(node, index) in nodes[currentNode].nodes"
          :key="`${currentNode}-${index}`"
          :component="node.component"
          :nodes="node.nodes"
          :hasInvalid="hasInvalid"
          @on-event="onEvent"
        />
      </div>
    </animation>
  </div>
  <loading v-else />
</template>

<script>
import { mapGetters } from "vuex";
import { setCurrentTrackNode } from "../scripts/data/track";
import { loadTrack } from "../scripts/data/track";
import Header from "../components/track/Header";
export default {
  props: ["id"],
  components: { Header },
  data: () => ({
    nodes: {},
    busy: false,
    hasInvalid: false,
    hasError: false,
    isLoading: true,
    errorTitle: "",
    errorMessage: "Cheque sua conexão e tente novamente",
  }),
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      currentNode: "currentNode",
      currentNodeValid: "currentNodeValid",
    }),
  },
  methods: {
    async loadData() {
      this.state = "load";
      this.hasError = false;
      this.isLoading = true;
      try {
        let json = await loadTrack(this.id);
        this.nodes = json.track;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.errorTitle = "Ocorreu um erro ao carregar os dados";
        this.hasError = true;
      }
    },
    async onEvent(event) {
      switch (event.type) {
        case "onValueUpdate":
          this.validateStep();
          break;
        case "nodeLink":
          this.$refs.track.scrollTop = 0;
          if (!event["validate"] || this.validateStep()) {
            await setCurrentTrackNode(event);
          }
          break;
        case "routerLink":
          this.$router.push(event.linkTo);
          break;
      }
    },
    validateStep() {
      let stepValid = true;

      let valid = this.currentNodeValid;
      if (valid) {
        for (var field in valid) {
          stepValid &= valid[field];
        }
      }
      this.hasInvalid = !stepValid;
      return stepValid;
    },
    changeBusyState(isBusy) {
      if (!isBusy) this.validateStep();
      this.busy = isBusy;
    },
  },
};
</script>
<style scoped>
.track-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 0;
  padding-left: 0;
  background-image: linear-gradient(to bottom, white 0%, white 100%),
    linear-gradient(
      to bottom,
      var(--v-primary-lighten3) 0%,
      var(--v-primary-lighten3) 100%
    );
  background-clip: content-box, padding-box;
  overflow: hidden;
}
.track-container.lg,
.track-container.xl {
  padding-right: 200px;
  padding-left: 200px;
}
.track-container.md {
  padding-right: 100px;
  padding-left: 100px;
}

.track {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  position: relative;
}
</style>
