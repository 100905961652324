import Vue from "vue";
import Vuex from "vuex";
import * as trackData from "./data/track";
import { getUserRoles } from "./auth"

Vue.use(Vuex);

let store = new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            data: null,
            roles: null
        },
        request: null,
        feedback: {
            trackRecord: null,
            trackState: {
                valid: {},
                currentNode: null,
            }
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        userId(state) {
            return state.user.data.uid;
        },
        currentFeedback(state) {
            return state.feedback;
        },
        currentRequest(state) {
            return state.request;
        },
        currentTrackRecord(state) {
            return state.feedback.trackRecord;
        },
        currentTrackState(state) {
            return state.feedback.trackState;
        },
        currentNode(state) {
            return state.feedback.trackState.currentNode
        },
        currentNodeValid(state) {
            return state.feedback.trackState.valid[state.feedback.trackState.currentNode]
        }
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, value) {
            state.user.data = value;
        },
        SET_USER_ROLE(state, value) {
            state.user.roles = value;
        },
        SET_CURRENT_REQUEST(state, value) {
            state.request = value;
        },
        SET_CURRENT_TRACK_RECORD(state, value) {
            state.feedback.trackRecord = value;
        },
        SET_CURRENT_TRACK_NODE(state, value) {
            state.feedback.trackState.currentNode = value
        },
        SET_CURRENT_TRACK_DATA(state, value) {
            state.feedback.trackRecord.data[value.prop] = value.value;

            let currentNode = state.feedback.trackState.currentNode;
            if (!state.feedback.trackState.valid[currentNode]) state.feedback.trackState.valid[currentNode] = {};
            state.feedback.trackState.valid[currentNode][value.prop] = value.valid;

        },
        SET_CURRENT_TRACK_CONTEXT(state, value) {
            state.feedback.trackRecord.context[value.prop] = value.value;

            let currentNode = state.feedback.trackState.currentNode;
            if (!state.feedback.trackState.valid[currentNode]) state.feedback.trackState.valid[currentNode] = {};
            state.feedback.trackState.valid[currentNode][value.prop] = value.valid;

        },
        ON_EVENT(state, event) {
            let logEntry = {};
            logEntry["createdOn"] = new Date();
            logEntry["from"] = state.feedback.trackState.currentNode;

            let eventEntry = {};
            for (var [eventKey, eventValue] of Object.entries(event)) {
                eventEntry[eventKey] = eventValue;
            }
            logEntry["event"] = eventEntry;

            state.feedback.trackRecord.log.push(logEntry);
            state.feedback.trackRecord.log = trackData.orderLog(state.feedback.trackRecord.log);

            if (event.endTrack)
                state.feedback.trackRecord.status = "closed";
        },
    },
    actions: {
        async fetchUser({ commit }, user) {
            commit("SET_LOGGED_IN", user !== null && !user.isAnonymous);
            if (user) {
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email,
                    uid: user.uid,
                    photo: user.photoURL
                });
                const roles = await getUserRoles();
                commit("SET_USER_ROLE", roles)
            } else {
                commit("SET_USER", null);
            }
        },
        setCurrentRequest({ commit }, value) {
            commit("SET_CURRENT_REQUEST", value);
        },
        setCurrentTrackRecord({ commit }, value) {
            commit("SET_CURRENT_TRACK_RECORD", value);
        },
        setCurrentTrackData({ commit }, value) {
            commit("SET_CURRENT_TRACK_DATA", value);
        },
        setCurrentTrackContext({ commit }, value) {
            commit("SET_CURRENT_TRACK_CONTEXT", value);
        },
        setCurrentTrackNode({ commit }, value) {
            commit("SET_CURRENT_TRACK_NODE", value)
        },
        logEvent({ commit }, value) {
            commit("ON_EVENT", value)
        },
    },
});

export default store