<template>
  <component :is="step.is" v-bind="step.options" class="step" @click="onClick">
    <slot></slot>
  </component>
</template>
<script>
import { setCurrentTrackData } from "../../scripts/data/track";
export default {
  name: "node-step",
  props: {
    step: Object,
  },
  computed: {
    eventEntry: function () {
      let event = {};

      event["type"] = this.step.type;
      event["linkTo"] = this.step.linkTo;

      if (this.step["id"]) event["id"] = this.step.id;

      if (this.step["sendData"]) event["sendData"] = this.step.sendData;

      if (this.step["endTrack"]) event["endTrack"] = this.step.endTrack;

      if (this.step["validate"]) event["validate"] = this.step.validate;

      if (this.step["setValue"])
      setCurrentTrackData({
          prop: this.step["setValue"],
          value: this.step.id,
          valid: true,
        });

      return event;
    },
  },
  methods: {
    onClick() {
      this.$emit("on-event", this.eventEntry);
    },
  },
};
</script>
<style lang="scss" scoped>
.step {
  cursor: pointer;
}
</style>
