import { db } from "../fb"
// import store from "../store"

let pageSize = 10;
// let field = "createdAt";
let requestCollection = "requests";
let currentQuery = null;
let nextDoc = null;
let previousDoc = null;
let firstId = null;
let lastId = null;
let snapshotListener = null;

// function reset() {
//     currentQuery = null;
//     nextDoc = null;
//     previousDoc = null;
//     firstId = null;
//     lastId = null;
// }

export async function get(successCallback, errorCallback) {
    try {
        if (!currentQuery) {
            currentQuery = db.collection(requestCollection)
                // .orderBy(field, 'desc')
                .limit(pageSize)
        }

        if (snapshotListener != null)
            snapshotListener();
        snapshotListener = currentQuery.onSnapshot((snap) => { handleGetResponde(snap, successCallback) }, errorCallback)

    } catch (error) {
        errorCallback(error);
    }
}

async function handleGetResponde(snap, successCallback) {
    if (snap.docs == 0) {
        successCallback({
            requests: [],
            hasPrevious: false,
            hasNext: false
        })
        return;
    }

    var result = []

    nextDoc = snap.docs[snap.docs.length - 1];
    previousDoc = snap.docs[0];
    snap.docs.forEach((doc, index) => {
        if (index < pageSize)
            result.push({ ...doc.data(), id: doc.id });
    });

    if (lastId == null && snap.docs.length < pageSize)
        lastId = nextDoc.id;

    if (firstId == null)
        firstId = previousDoc.id

    successCallback({
        requests: result,
        hasPrevious: firstId != previousDoc.id,
        hasNext: lastId != nextDoc.id
    })
}

export async function next(successCallback, errorCallback) {
    currentQuery = db.collection(requestCollection)
        // .orderBy(field, 'desc')
        .startAt(nextDoc)
        .limit(pageSize);
    return await get(successCallback, errorCallback);
}

export async function previous(successCallback, errorCallback) {
    currentQuery = db.collection(requestCollection)
        // .orderBy(field, 'desc')
        .endAt(previousDoc)
        .limitToLast(pageSize);
    return await get(successCallback, errorCallback);
}

// export function changeOrder(newField, successCallback, errorCallback) {
//     field = newField
//     reset();
//     get(successCallback, errorCallback);
// }

export async function setRequest(request, successCallback, errorCallback) {
    try {
        var doc = db.collection("requests").doc(request.id);

        delete request.id;

        await doc.set(request);

        successCallback();
    } catch (error) {
        errorCallback(error)
    }
}

export default class DataGetter {
    constructor(sourceParams, queryParams, successCallback, errorCallback) {
        this.pageSize = 0;
        this.orderBy = null;
        this.orderType = "desc";

        this.querySource = null;
        this.currentQuery = null;
        this.snapshotListener = null;
        this.lastDoc = null;

        this.initialize(sourceParams, queryParams);
        this.get(successCallback, errorCallback);
    }

    async initialize(sourceParams, queryParams) {

        this.reset();

        if (queryParams.pageSize)
            this.pageSize = queryParams.pageSize

        if (queryParams.orderBy)
            this.orderBy = queryParams.orderBy

        if (queryParams.orderType)
            this.orderType = queryParams.orderType

        this.querySource = null;
        sourceParams.forEach(param => {
            if (this.querySource === null) {
                if (param.type === 'collection')
                    this.querySource = db.collection(param.id)
                else if (param.type === 'doc')
                    this.querySource = db.doc(param.id)
            }
            else {
                if (param.type === 'collection')
                    this.querySource = this.querySource.collection(param.id)
                else if (param.type === 'doc')
                    this.querySource = this.querySource.doc(param.id)
            }
        })

    }

    reset() {
        this.currentQuery = null;
        this.lastDoc = null;
        this.orderBy = null;
        this.orderType = 'desc';
        this.pageSize = 20
    }

    async get(successCallback, errorCallback) {
        try {
            this.currentQuery = this.querySource;
            if (this.orderBy !== null)
                this.currentQuery = this.currentQuery.orderBy(this.orderBy, this.orderType);
            if (this.lastDoc !== null)
                this.currentQuery = this.currentQuery.startAt(this.lastDoc)
            if (this.pageSize > 0)
                this.currentQuery = this.currentQuery.limit(this.pageSize + 1)

            if (this.snapshotListener != null)
                this.snapshotListener();
            this.snapshotListener = this.currentQuery.onSnapshot((snap) => { this.handleGetResponde(snap, successCallback) }, errorCallback)

        } catch (error) {
            errorCallback(error);
        }
    }

    async handleGetResponde(snap, successCallback) {
        let responseObject = {
            items: [],
            hasNext: false
        }

        if (snap.docs == 0) {
            successCallback(responseObject)
            return;
        }

        let docs = snap.docs;

        if (docs.length == this.pageSize + 1) {
            this.lastDoc = docs.pop();
            responseObject.hasNext = true;
        }
        else {
            this.lastDoc = docs.length[docs.length - 1]
            responseObject.hasNext = false;
        }

        responseObject.items = docs.map(doc => ({ id: doc.id, ...doc.data() }));

        successCallback(responseObject)
    }
}