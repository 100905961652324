<template>
  <transition
    appear
    v-on:enter="onEnter"
    v-on:leave="onLeave"
    v-bind:css="false"
    :mode="mode"
  >
    <slot></slot>
  </transition>
</template>
<script>
import anime from "animejs/lib/anime.es.js";
export default {
  name: "animation",
  props: {
    enterAnimation: { type: String, default: "slide" },
    enterDuration: { type: Number, default: 1000 },
    enterStaggerDelay: { type: Number, default: 64 },
    leaveAnimation: { type: String, default: "slide" },
    leaveDuration: { type: Number, default: 300 },
    leaveStaggerDelay: { type: Number, default: 64 },
    mode: { type: String, default: "out-in" },
  },
  data: () => ({
    animations: {
      slide: {
        enter: {
          translateX: ["-10%", 0],
          opacity: [0, 1],
        },
        leave: {
          translateX: [0, "10%"],
          opacity: [1, 0],
          easing: "easeInOutSine",
        },
      },
    },
  }),
  methods: {
    onEnter(el, done) {
      this.$emit("enter-start");
      var animation = {
        targets: el.querySelectorAll(".enter-animation-target"),
        duration: this.enterDuration,
        delay: anime.stagger(this.enterStaggerDelay),
        complete: () => {
          done();
          this.$emit("enter-stop");
        },
      };
      animation = {
        ...animation,
        ...this.animations[this.enterAnimation].enter,
      };
      anime(animation);
    },
    onLeave(el, done) {
      this.$emit("leave-start");
      var animation = {
        targets: el.querySelectorAll(".leave-animation-target"),
        duration: this.leaveDuration,
        delay: anime.stagger(this.leaveStaggerDelay),
        complete: () => {
          done();
          this.$emit("leave-stop");
        },
      };
      animation = {
        ...animation,
        ...this.animations[this.leaveAnimation].leave,
      };
      anime(animation);
    },
  },
};
</script>