<template>
  <div>
    <v-navigation-drawer app>
      <v-container>
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text block v-bind="attrs" v-on="on"> Nova requisição </v-btn>
          </template>

          <v-card>
            <v-container>
              <v-text-field
                v-model="newRequestId"
                label="ID"
                outlined
                hide-details
              ></v-text-field>
            </v-container>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                small
                text
                @click="
                  () => {
                    newRequestId = null;
                    dialog = false;
                  }
                "
              >
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" small text @click="newRequest">
                Adicionar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="request in items"
          :key="request.id"
          :class="{ grey: selected == request }"
          @click="handleRowClick(request)"
        >
          <v-list-item-content> {{ request.id }} </v-list-item-content>
          <v-list-item-action>
            <v-btn fab x-small elevation="0" :to="`/termometro/${request.id}`">
              <v-icon> mdi-thermometer </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-btn
              color="primary"
              :disabled="!hasNext"
              :loading="loading"
              @click="nextPage"
            >
              Carregar mais
            </v-btn>
            <div class="text-uppercase text-caption text-center">
              Total carregado: {{ items.length }}
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container>
      <request-form
        v-if="selected"
        :request="selected"
        :key="selected.id"
      ></request-form>
    </v-container>
  </div>
</template>
<script>
import RequestForm from "../components/requests/RequestForm.vue";
import DataGetter from "../scripts/data/request";
import { setRequest } from "../scripts/data/request";
export default {
  components: { RequestForm },
  data: () => ({
    dialog: false,
    newRequestId: null,
    getter: null,
    items: [],
    hasNext: false,
    loading: false,
    orderBy: null,
    orderByList: [],
    orderType: "desc",
    orderTypeList: [],
    pageSize: 20,
    pageSizeList: [],
    selected: null,
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.submitFilter();
    },
    submitFilter() {
      this.items = [];
      this.getter = new DataGetter(
        [{ id: "requests", type: "collection" }],
        {
          pageSize: this.pageSize,
          orderBy: this.orderBy,
          orderType: this.orderType,
        },
        this.handleDataLoadSuccessCallback,
        this.handleDataLoadErrorCallback
      );
    },
    nextPage() {
      if (this.loading) return;
      this.loading = true;
      this.getter.get(
        this.handleDataLoadSuccessCallback,
        this.handleDataLoadErrorCallback
      );
    },
    handleDataLoadSuccessCallback(data) {
      this.items = [...new Set(data.items)];
      this.hasNext = data.hasNext;
      this.loading = false;
    },
    handleDataLoadErrorCallback(error) {
      console.log(error);
      this.loading = false;
    },
    handleRowClick(item) {
      if (this.selected === item) this.selected = null;
      else this.selected = item;
    },
    newRequest() {
      if (!this.newRequestId) return;
      let request = {
        id: this.newRequestId,
        track: "padrao",
        fieldSets: {},
        data: {},
      };
      setRequest(request, this.setSuccessCallback, this.setSuccessCallback);
    },
    setSuccessCallback() {
      this.newRequestId = null;
    },
    errorCallback(error) {
      console.log(error);
    },
  },
};
</script>