import {
    db,
    storage
} from "../fb.js";
import store from "../store"

let requestId;
export let trackData;
export let trackRequestData;

export function getTrackRecordDoc() {
    return db.collection("requestFeedback").doc(requestId).collection("userTrackRecord").doc(store.getters.userId)
}

export function getStageDataDoc() {
    return db.collection("requestFeedback").doc(requestId).collection("stageData").doc(store.getters.userId)
}
export function getTrackCommentCollection() {
    return db.collection("requestFeedback").doc(requestId).collection("comments");
}

export async function getUserTrackRecord() {

    let snap = await getTrackRecordDoc().get();

    let trackRecord = snap.exists ? snap.data() : null;

    var lsData = getLocalStorageData();
    if (lsData)
        trackRecord = { ...trackRecord, ...lsData };

    if (!trackRecord) {
        trackRecord = newTrackRecord();
    }

    if (trackRecord.log.length > 0)
        trackRecord.log = orderLog(trackRecord.log)

    return trackRecord;
}

function newTrackRecord() {
    return {
        status: "open",
        data: {},
        context: {},
        log: []
    }
}

function getLocalStorageId() {
    return requestId + "_" + store.getters.userId;
}

function setLocalStorageData(trackRecord) {
    var id = getLocalStorageId();
    localStorage.setItem(id, JSON.stringify(trackRecord))
}

function getLocalStorageData() {
    var id = getLocalStorageId();
    var data = localStorage.getItem(id) ? JSON.parse(localStorage.getItem(id)) : null;
    return data
}

async function sendTrackRecord(trackRecord) {
    await getTrackRecordDoc().set(trackRecord, { merge: true })
}

export function orderLog(log) {
    return log.sort(function (a, b) {
        if (a.createdOn < b.createdOn) {
            return -1;
        }
        if (a.createdOn > b.createdOn) {
            return 1;
        }
        return 0;
    });
}


const loadTrackFunction = {
    development: async function (id) {
        const requireContext = require.context("../../assets/tracks", true, /\.json$/);
        var keys = requireContext.keys().filter(key => key.includes(`./${id}/data/nodes`))
        let result = { entryPoint: null, track: {} };

        keys.forEach((key) => {
            const obj = JSON.parse(JSON.stringify(requireContext(key)));
            const path = key.split("/");
            const id = path[path.length - 1].replace(".json", "");
            obj["id"] = id
            result.track[id] = obj;
            if (obj["entryPoint"]) result.entryPoint = id;
        });
        return result
    },
    production: async function (id) {
        try {
            var listRef = storage.ref("tracks/" + id + "/data/nodes");
            var res = await listRef.listAll();
            console.log(res)
            let result = { entryPoint: null, track: {} };
            var promises = res.items.map(async (element) => {
                let url = await element.getDownloadURL();
                let json = await fetch(url).then((r) => r.json());
                result.track[json.id] = json;
                if (json["entryPoint"]) result.entryPoint = json.id;
            });
            await Promise.all(promises);
            return result;
        } catch (error) {
            console.log(error)
        }
    }
}

export async function getTrackRequestData(id) {
    if (trackRequestData == null || id != requestId) {
        await loadTrackRequestData(id);
    }
    return trackRequestData;
}
async function loadTrackRequestData(id) {
    requestId = id;
    const snap = await db.collection("requests").doc(id).get();
    trackRequestData = snap.data();
}

export async function loadTrack(id) {
    try {
        await getTrackRequestData(id)
        store.dispatch("setCurrentRequest", id);

        var result = await loadTrackFunction["development"](trackRequestData.track);

        var trackRecord = await getUserTrackRecord();
        store.dispatch("setCurrentTrackRecord", trackRecord);
        setLocalStorageData(trackRecord);
        await sendTrackRecord(trackRecord);

        var lastNode = result.entryPoint;
        if (trackRecord.log.length > 0) {
            let log = trackRecord.log;
            for (var i in log) {
                if (log[i].event &&
                    log[i].event.linkTo &&
                    log[i].event.type == "nodeLink") {
                    lastNode = log[i].event.linkTo;
                }
                lastNode = log[i].event.linkTo
            }
        }
        result.entryPoint = lastNode;
        store.dispatch("setCurrentTrackNode", result.entryPoint);

        trackData = result;

        return trackData;

    } catch (error) {
        console.log(error)
    }
}

export function setCurrentTrackData(data) {
    if (data.isContext)
        store.dispatch("setCurrentTrackContext", data);
    else
        store.dispatch("setCurrentTrackData", data);

    setLocalStorageData(store.getters.currentTrackRecord);
}

export async function setCurrentTrackNode(event) {

    store.dispatch("logEvent", event);
    store.dispatch("setCurrentTrackNode", event.linkTo);

    setLocalStorageData(store.getters.currentTrackRecord);

    if (event.sendData)
        await sendTrackRecord(store.getters.currentTrackRecord);
}

export async function sendComment(comment) {
    let data = { comment: comment, userId: store.getters.userId };
   await getTrackCommentCollection().add(data);
}