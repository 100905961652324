<template>
  <v-container fill-height align-start fluid pa-0 ma-0>
    <v-container>
      <span class="text-h4 text-h4 grey--text">{{ dropzone.label }}</span>
    </v-container>
    <v-container style="padding-bottom: 120px">
      <v-row
        :class="['dropzone', 'shelf', dragging ? 'open' : 'closed']"
        id="shelf"
        sortable
      >
        <v-col
          v-for="item in items"
          :key="item.id"
          :id="item.id"
          md="4"
          sm="6"
          sortItem
          class="draggable"
        >
          <div class="draggable-card">
            {{ item.label }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="folder-container" @mouseleave="mouseLeave">
      <div style="margin-left: 1rem; width: 15rem" @mouseenter="mouseEnter">
        <pic file="folderTab.svg" width="20px" />
      </div>
      <v-container
        px-10
        py-6
        fluid
        @mouseenter="mouseEnter"
        :class="['folder', folder ? 'open' : 'closed']"
      >
        <v-row class="dropzone" id="folder" sortable>
          <v-col
            v-for="item in selected"
            :key="item.id"
            :id="item.id"
            md="4"
            sm="6"
            sortItem
            class="draggable"
          >
            <div class="draggable-card">
              {{ item.label }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>
<script>
import { Sortable } from "@shopify/draggable";
import store from "../../scripts/store";
export default {
  name: "sortable",
  props: ["draggables", "dropzone"],
  data: () => ({
    items: [],
    selected: [],
    draggable: null,
    dragging: false,
    folder: false,
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var storedData = store.getters.currentTrack.data[this.dropzone.prop];
      if (storedData && storedData.length) {
        this.selected = storedData;
      }
      var all = JSON.parse(JSON.stringify(this.draggables.items));
      this.items = all.filter(
        (el) => !this.selected.find((sel) => sel.id == el.id)
      );
      this.sortable = new Sortable(document.querySelectorAll("[sortable]"), {
        draggable: "[sortItem]",
      });
      this.sortable.on("drag:start", () => {
        this.dragging = true;
        this.folder = true;
      });
      this.sortable.on("drag:stop", this.dragStop);
    },
    dragStop(e) {
      var itemId = e.data.source.id;
      var containerId = e.data.source.parentNode.id;
      this.moveItem(itemId, containerId);
      this.dragging = false;
      this.folder = false;
      this.onValueUpdate();
    },
    moveItem(itemId, containerId) {
      var item = null;
      if (containerId == "folder" && this.items.find((el) => el.id == itemId)) {
        item = this.items.find((el) => el.id == itemId);
        this.items.splice(this.items.indexOf(item), 1);
        this.selected.push(item);
      } else if (
        containerId == "shelf" &&
        this.selected.find((el) => el.id == itemId)
      ) {
        item = this.selected.find((el) => el.id == itemId);
        this.selected.splice(this.selected.indexOf(item), 1);
        this.items.push(item);
      }
    },
    mouseEnter() {
      if (!this.folder) this.folder = true;
    },
    mouseLeave() {
      if (this.folder && !this.dragging) this.folder = false;
    },
    onValueUpdate() {
      store.dispatch("setCurrentTrackData", {
        prop: this.dropzone.prop,
        value: this.selected,
        valid: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.draggable-card {
  background: var(--v-secondary-base);
  border-radius: 1rem;
  padding: 1rem;
  min-height: 160px;
  max-height: 160px;
  // max-width: 40vw;
  max-width: 160px;
  font-size: 1rem;
  vertical-align: middle;
  box-shadow: 2px 2px 10px rgba(black, 0.2);
}
.draggable {
  cursor: grab;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.draggable-source--is-dragging .draggable-card {
  background: var(--v-secondary-darken1);
}
.draggable-mirror .draggable-card {
  z-index: 99;
  box-shadow: 0px 0px 0px black;
  cursor: grab !important;
}
.dropzone {
  min-height: 160px;
}
.dropzone.shelf {
  min-height: 50vh;
  border-radius: 10px;
}
.dropzone.open {
  transition: background-color 1.2s;
  background-color: rgba(black, 0.1);
}
.dropzone.closed {
  transition: background-color 400ms;
  background-color: rgba(black, 0);
}
.folder-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.folder {
  background: var(--v-primary-lighten2);
  overflow: hidden;
}
.folder.closed {
  max-height: 0px;
  transition: max-height 1200ms;
}
.folder.open {
  max-height: 800px;
  transition: max-height 400ms;
}
</style>
