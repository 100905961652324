<template>
  <v-container pa-0 ma-0 fluid>
    <transition
      appear
      v-on:enter="enter"
      v-on:leave="leave"
      v-bind:css="false"
      mode="out-in"
    >
      <track-control
        v-if="header && header.type == 'trackControl' && header.trackControl"
        :trackControl="header.trackControl"
        :disable-next-step="disableNextStep"
        :disable-previous-step="disablePreviousStep"
        @on-event="onEvent"
      />
      <v-row v-else-if="header && header.type == 'brand'" justify="center">
        <brand size="lg" class="py-8" />
      </v-row>
    </transition>
  </v-container>
</template>
<script>
import anime from "animejs/lib/anime.es.js";
import TrackControl from "./TrackControl";
export default {
  props: {
    header: { default: null, type: Object },
    disableNextStep: { default: false, type: Boolean },
    disablePreviousStep: { default: false, type: Boolean },
  },
  components: { TrackControl },
  methods: {
    onEvent(event) {
      this.$emit("on-event", event);
    },
    enter(el, done) {
      this.busy = true;
      anime({
        targets: el,
        translateX: ["-10%", 0],
        duration: 1000,
        opacity: [0, 1],
        delay: anime.stagger(64),
        complete: () => {
          this.busy = false;
          done();
        },
      });
    },
    leave(el, done) {
      this.busy = true;
      anime({
        targets: el,
        duration: 300,
        translateX: [0, "10%"],
        opacity: [1, 0],
        easing: "easeInOutSine",
        delay: anime.stagger(64, { direction: "reverse" }),
        complete: () => {
          this.busy = false;
          done();
        },
      });
    },
  },
};
</script>