<template>
  <v-container>
    <v-row class="mb-4">
      <span class="text-h5">Requisição</span>
      <v-spacer></v-spacer>
      <v-btn v-if="edit" text @click="init"> Cancelar </v-btn>
      <v-btn v-if="edit" text @click="set" color="primary"> Salvar </v-btn>
      <v-btn v-if="!edit" text @click="edit = true" color="primary">
        Editar
      </v-btn>
    </v-row>

    <v-form v-model="formValid">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.track"
              label="TRACK"
              outlined
              hide-details
              :disabled="!edit"
              @input="form.track = form.track.toLowerCase()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="form.data">
            <v-card>
              <v-card-title>Textos</v-card-title>
              <v-container>
                <div
                  v-for="(data, prop) in form.data"
                  :key="`${prop}`"
                  class="d-flex my-2"
                >
                  <v-text-field
                    v-model="form.data[prop]"
                    :label="prop"
                    class="mr-2"
                    outlined
                    hide-details
                    :disabled="!edit"
                  ></v-text-field>
                  <v-btn
                    v-if="edit"
                    text
                    class="mt-auto"
                    :disabled="!edit"
                    @click="$delete(form.data, prop)"
                  >
                    Remover
                  </v-btn>
                </div>
                <v-divider class="my-4"></v-divider>
                <div v-if="edit" class="d-flex">
                  <v-text-field
                    v-model="newData"
                    label="Adicionar novo ID"
                    class="mr-2"
                    outlined
                    hide-details
                    :disabled="!edit"
                    @input="newData = newData.toLowerCase()"
                  ></v-text-field>
                  <v-btn
                    text
                    class="mt-auto"
                    :disabled="!edit"
                    @click="
                      () => {
                        $set(form.data, newData, '');
                        newData = '';
                      }
                    "
                  >
                    Adicionar
                  </v-btn>
                </div>
              </v-container>
            </v-card>
          </v-col>
          <v-col>
            <v-card cols="12">
              <v-card-title>Campos</v-card-title>
              <div v-for="(fieldSet, fieldId) in form.fieldSets" :key="fieldId">
                <v-card-title>
                  <span> id: {{ fieldId }}</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="edit"
                    class="mt-auto"
                    text
                    :disabled="!edit"
                    @click="() => {}"
                  >
                    Remover
                  </v-btn>
                </v-card-title>
                <v-container>
                  <div
                    v-for="(set, index) in fieldSet"
                    :key="`${fieldId}-${index}`"
                    class="d-flex mb-4"
                  >
                    <v-text-field
                      v-model="form.fieldSets[fieldId][index].id"
                      @input="
                        newFieldSet =
                          form.fieldSets[fieldId][index].id.toLowerCase()
                      "
                      label="id"
                      class="mr-2"
                      outlined
                      hide-details
                      :disabled="!edit"
                    ></v-text-field>
                    <v-text-field
                      v-model="form.fieldSets[fieldId][index].label"
                      label="label"
                      class="mr-2"
                      outlined
                      hide-details
                      :disabled="!edit"
                    ></v-text-field>
                    <v-btn
                      v-if="edit"
                      class="mt-auto"
                      text
                      :disabled="!edit"
                      @click="form.fieldSets[fieldId].splice(index, 1)"
                    >
                      Remover
                    </v-btn>
                  </div>
                </v-container>
                <div v-if="edit">
                  <v-divider></v-divider>
                  <v-container>
                    <v-btn
                      text
                      class="mt-auto"
                      :disabled="!edit"
                      @click="
                        form.fieldSets[fieldId].push({ id: '', label: '' })
                      "
                    >
                      Adicionar
                    </v-btn>
                  </v-container>
                </div>
                <v-divider></v-divider>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="edit">
            <div class="d-flex">
              <v-text-field
                v-model="newFieldSet"
                label="Novo FieldSet"
                class="mr-2"
                outlined
                hide-details
                :disabled="!edit"
                @input="newFieldSet = newFieldSet.toLowerCase()"
              ></v-text-field>
              <v-btn
                text
                class="mt-auto"
                :disabled="!edit"
                @click="
                  () => {
                    $set(form.fieldSets, newFieldSet, []);
                    newFieldSet = '';
                  }
                "
              >
                Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>
<script>
import { setRequest } from "../../scripts/data/request";
export default {
  props: ["request"],
  data: () => ({
    edit: false,
    formValid: false,
    form: {},
    newFieldSet: null,
    newData: null,
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.form = JSON.parse(JSON.stringify(this.request));
      this.edit = false;
    },
    set() {
      if (!this.edit) return;
      setRequest(this.form, this.setSuccessCallback, this.errorCallback);
    },
    setSuccessCallback() {
      this.edit = false;
    },
    errorCallback(error) {
      console.log(error);
    },
  },
};
</script>