<template>
  <component
    :is="component.is"
    v-bind="component.options"
    :hasInvalid="hasInvalid"
    @on-event="onEvent"
  >
    {{ component.content }}
    <node-tree
      v-for="(node, index) in nodes"
      :key="`node-${index}`"
      :component="node.component"
      :nodes="node.nodes"
      :hasInvalid="hasInvalid"
      @on-event="onEvent"
    />
  </component>
</template>

<script>
export default {
  name: "node-tree",
  props: {
    component: Object,
    nodes: Array,
    isActive: Boolean,
    hasInvalid: Boolean,
  },
  methods: {
    onEvent(event) {
      this.$emit("on-event", event);
    },
  },
};
</script>
