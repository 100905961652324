<template>
  <v-overlay absolute :value="loading">
    <div v-if="!error" class="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <v-alert type="error" v-else>
      <h3 class="headline">
        {{ errorTitle }}
      </h3>
      <div>{{ errorMessage }}</div>
      <v-divider class="mb-4"></v-divider>
      <v-btn block @click="retry">{{ retryButton }}</v-btn>
    </v-alert>
  </v-overlay>
</template>
<script>
export default {
  name: "loader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTitle: {
      type: String,
      default: "Ocorreu um erro.",
    },
    errorMessage: {
      type: String,
      default: "Verifique sua conexão e tente novamente.",
    },
    retryButton: {
      type: String,
      default: "Tentar novamente",
    },
  },
  methods: {
    retry() {
      this.$emit("retry");
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay-fill {
  height: 100%;
  width: 100%;
}
.loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 35px 5px;
}
.loader span:nth-child(1) {
  animation: bounce 2s ease-in-out infinite;
}
.loader span:nth-child(2) {
  animation: bounce 2s ease-in-out 0.2s infinite;
}
.loader span:nth-child(3) {
  animation: bounce 2s ease-in-out 0.4s infinite;
}
@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    background-color: black;
  }
  12.5% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    background-color: var(--v-primary-base);
  }
  62.5% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    background-color: var(--v-secondary-base);
  }
}
</style>