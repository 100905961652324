<template>
  <div class="loading-container">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: "loading",
};
</script>
<style scoped>
.loading-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
</style>