<template>
  <v-img :lazy-src="lazySrc" :src="src" contain>
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>
<script>
export default {
  name: "pic",
  props: ["image", "mobile", "track"],
  computed: {
    src: function () {
      var source = null;
      if (this.$vuetify.breakpoint.mobile && this.mobile) {
        source = this.track
          ? require("../../assets/tracks/" +
              this.track +
              "/data/images/" +
              this.mobile)
          : require("../../assets/images/" + this.mobile);
      } else {
        source = this.track
          ? require("../../assets/tracks/" +
              this.track +
              "/data/images/" +
              this.image)
          : require("../../assets/images/" + this.image);
      }
      return source;
    },
    lazySrc: function () {
      return this.track
        ? require("../../assets/tracks/" +
            this.track +
            "/data/images/lazy/" +
            this.image)
        : require("../../assets/images/lazy/" + this.image);
    },
  },
};
</script>