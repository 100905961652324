import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/analytics"
import store from "./store";

const firebaseConfig = {
    apiKey: "AIzaSyBOhwXimL2Q61MLtbe2KOUDNBjjIr0LWCk",
    authDomain: "amelita-flowr.firebaseapp.com",
    projectId: "amelita-flowr",
    storageBucket: "amelita-flowr.appspot.com",
    messagingSenderId: "67149720992",
    appId: "1:67149720992:web:2812415fc1c74c3d6748c5",
    measurementId: "G-1L247Q7TFB"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

auth.onAuthStateChanged(user => {
    store.dispatch("fetchUser", user);
});

const db = firebase.firestore();
db.settings({
    experimentalForceLongPolling: true
});

const storage = firebase.storage();

export { auth, googleAuthProvider, db, storage, };
