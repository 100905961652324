var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.trackControl.progress)?_c('v-progress-linear',{attrs:{"height":_vm.$vuetify.breakpoint.mobile ? '10px' : '20px',"value":(_vm.trackControl.progress.current / _vm.trackControl.progress.total) * 100,"background-color":"black","background-opacity":"0.1"}}):_vm._e()],1),_c('v-container',{attrs:{"py-4":"","px-10":""}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-row',{staticStyle:{"min-height":"50px"},attrs:{"align":"center","justify":"space-between"}},[(_vm.trackControl.previous)?_c('node-step',{attrs:{"step":Object.assign({}, _vm.trackControl.previous,
          {is: 'v-btn',
          options: {
            color: 'grey',
            dark: true,
            elevation: 0,
            'x-small': true,
            fab: true,
            disabled: _vm.disablePreviousStep,
          }})},on:{"on-event":_vm.onEvent}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-chevron-left")])],1):_vm._e(),_c('v-col',[_c('animation',[(_vm.trackControl.title)?_c('h1',{key:_vm.trackControl.title,staticClass:"\n              text-h6\n              header--text\n              enter-animation-target\n              leave-animation-target\n            ",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(_vm.trackControl.title)+" ")]):_vm._e()])],1),(_vm.trackControl.next)?_c('node-step',{attrs:{"step":Object.assign({}, _vm.trackControl.next,
          {is: 'v-btn',
          options: {
            color: 'primary',
            elevation: 0,
            'x-small': true,
            fab: true,
            disabled: _vm.disableNextStep,
          }})},on:{"on-event":_vm.onEvent}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1):_c('v-row',{staticStyle:{"min-height":"100px"},attrs:{"align":"center"}},[_c('v-col',[_c('animation',[(_vm.trackControl.subtitle)?_c('h5',{key:_vm.trackControl.subtitle,staticClass:"\n              text-h7\n              primary--text\n              text-uppercase\n              font-weight-bold\n              enter-animation-target\n              leave-animation-target\n            "},[_vm._v(" "+_vm._s(_vm.trackControl.subtitle)+" ")]):_vm._e()]),_c('animation',[(_vm.trackControl.title)?_c('h1',{key:_vm.trackControl.title,staticClass:"text-h5 header--text"},[_vm._v(" "+_vm._s(_vm.trackControl.title)+" ")]):_vm._e()])],1),_c('div',{staticClass:"mr-8"},[(_vm.trackControl.previous)?_c('node-step',{attrs:{"step":Object.assign({}, _vm.trackControl.previous,
            {is: 'v-btn',
            options: {
              color: 'grey',
              dark: true,
              elevation: 0,
              small: true,
              fab: true,
              disabled: _vm.disablePreviousStep,
            }})},on:{"on-event":_vm.onEvent}},[_c('v-icon',{attrs:{"size":"52"}},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('div',[(_vm.trackControl.next)?_c('node-step',{attrs:{"step":Object.assign({}, _vm.trackControl.next,
            {is: 'v-btn',
            options: {
              color: 'primary',
              elevation: 0,
              small: true,
              fab: true,
              disabled: _vm.disableNextStep,
            }})},on:{"on-event":_vm.onEvent}},[_c('v-icon',{attrs:{"size":"52"}},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }