<template>
  <span v-html="text"></span>
</template>
<script>
import { trackRequestData } from "../../scripts/data/track";
export default {
  name: "text-from-data",
  props: ["prop", "fallbackText"],
  computed: {
    text: function () {
      if (trackRequestData["data"] && trackRequestData["data"][this.prop])
        return trackRequestData["data"][this.prop];
      else if (this.fallbackText) return this.fallbackText;
      else return "";
    },
  },
};
</script>