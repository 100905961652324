<template>
  <v-container py-16>
    <v-row justify="start" align="center">
      <v-col v-for="item in formatedStats" :key="item.key" cols="4">
        <v-card
          height="280"
          width="300"
          class="mx-auto"
          elevation="4"
          color="secondary"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-icon color="primary" size="60" class="mr-2">
                mdi-account-group
              </v-icon>
              <div class="text-overline">
                {{ item.key }}
              </div>
              <v-list-item-title>
                <v-list-item>
                  <span class="text-h1">
                    {{ item.value }}
                  </span>
                </v-list-item>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["stats"],
  computed: {
    formatedStats: function() {
      return Object.keys(this.stats)
        .map((key) => ({
          key: key.replaceAll("_", " "),
          value: this.stats[key],
        }))
        .filter((el) => !el.key.toLocaleLowerCase().includes("choice"))
        .sort((a, b) => {
          if (a.key < b.key) {
            return -1;
          }
          if (a.key > b.key) {
            return 1;
          }
          return 0;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.v-list-item {
  height: 100%;
  text-align: center;

  span {
    margin: 0 auto;
  }

  .text-overline {
    font-weight: 600;
  }
}

@media only screen and (max-width: 600px) {
  .row {
    display: flex;
    flex-direction: column;
    .col-4 {
      max-width: 95%;
    }
  }
  .v-list-item {
    text-align: center;
    height: 100%;
    min-height: auto;

    .v-list-item__title {
      .v-list-item {
        display: flex;
        flex-direction: column;
        .v-icon {
          width: 10%;
        }
        span {
          margin: 0 auto;
        }
      }
    }
    .text-overline {
      font-weight: 600;
    }
  }
}
</style>
