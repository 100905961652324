<template>
  <component
    :is="step.is"
    v-bind="step.options"
    ref="swipe"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <slot></slot>
  </component>
</template>
<script>
import { setCurrentTrackData } from "../../scripts/data/track";
export default {
  name: "node-step-swipe",
  data: () => ({
    xDown: null,
    xUp: null,
    yDown: null,
    yUp: null,
    direction: null,
  }),
  props: {
    step: Object,
  },
  methods: {
    onStep() {
      let event = {};

      event["type"] = this.direction.type;
      event["linkTo"] = this.direction.linkTo;

      if (this.direction["id"]) event["id"] = this.direction.id;

      if (this.direction["sendData"])
        event["sendData"] = this.direction.sendData;

      if (this.direction["endTrack"])
        event["endTrack"] = this.direction.endTrack;

      if (this.direction["validate"])
        event["validate"] = this.direction.validate;

      if (this.direction["setValue"])
       setCurrentTrackData({
          prop: this.direction["setValue"],
          value: this.direction.id,
          valid: true,
        });

      this.$emit("on-event", event);
      this.direction = null;
    },
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches;
    },
    handleTouchStart(evt) {
      const firstTouch = this.getTouches(evt)[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      const touch = this.getTouches(evt)[0];

      this.xUp = touch.clientX;
      this.yUp = touch.clientY;
    },
    handleTouchEnd() {
      var xDiff = this.xDown - this.xUp;
      var yDiff = this.yDown - this.yUp;
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          this.direction = this.step.right;
        } else {
          this.direction = this.step.left;
        }
      }
      if (this.direction) this.onStep();
    },
  },
};
</script>