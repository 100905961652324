<template>
  <v-container fluid fill-height class="bg">
    <v-container fluid pa-0>
      <v-row justify="center" align="center">
        <v-col md="6" cols="12" class="pa-0 d-flex justify-center">
          <v-card max-width="800px" width="100%" outlined elevation="12">
            <v-tabs v-model="state" color="primary" show-arrows grow>
              <v-tab>
                <v-icon color="secondary">
                  mdi-account-arrow-right-outline
                </v-icon>
                <small class="ml-2">Iniciar sessão</small>
              </v-tab>
              <v-tab>
                <v-icon color="secondary">mdi-account-question-outline</v-icon>
                <small class="ml-2">Esqueci a senha</small>
              </v-tab>
            </v-tabs>

            <v-container fluid class="py-5">
              <v-tabs-items v-model="state">
                <v-tab-item>
                  <sign-in-form @change-state="changeState" />
                </v-tab-item>
                <v-tab-item>
                  <pass-reset />
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import SignInForm from "../components/auth/SignInForm.vue";
import PassReset from "../components/auth/PassReset.vue";
export default {
  components: {  SignInForm, PassReset },
  data: () => ({
    state: 0,
  }),
  mounted() {
    let props = Object.keys(this.$route.query);
    if (props.indexOf("cadastro") > -1) {
      this.changeState(1);
    } else if (props.indexOf("esqueci") > -1 || props.indexOf("senha") > -1) {
      this.changeState(2);
    }
  },
  methods: {
    changeState(state) {
      this.state = state;
    },
  },
};
</script>