<template>
  <v-container>
    <v-row justify="end" class="mb-4 export-btn-container">
      <v-col cols="2">
        <v-btn block @click="exportAndDownload">Exportar</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :items="formatedData"
      :headers="headers"
      :items-per-page="-1"
      :hide-default-footer="true"
      @current-items="getFiltered"
    >
      <template v-slot:[`item.pillar`]="{ item }">
        <v-chip
          dark
          :outlined="pillarFilter == ''"
          :color="getPillarColor(item.pillar)"
          @click="
            () => {
              if (pillarFilter == '') pillarFilter = item.pillar;
              else pillarFilter = '';
            }
          "
        >
          {{ getLabel(item.pillar).toUpperCase() }}
        </v-chip>
      </template>
      <template v-slot:[`item.stage`]="{ item }">
        <v-chip
          :dark="stageFilter != ''"
          :outlined="stageFilter == ''"
          @click="
            () => {
              if (stageFilter == '') stageFilter = item.stage;
              else stageFilter = '';
            }
          "
        >
          {{ getLabel(item.stage).toUpperCase() }}
        </v-chip>
      </template>
      <template v-slot:[`item.dimension`]="{ item }">
        <v-chip
          :dark="dimensionFilter != ''"
          :outlined="dimensionFilter == ''"
          @click="
            () => {
              if (dimensionFilter == '') dimensionFilter = item.dimension;
              else dimensionFilter = '';
            }
          "
        >
          {{ getLabel(item.dimension).toUpperCase() }}
        </v-chip>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <v-chip
          :dark="valueFilter != ''"
          :outlined="valueFilter == ''"
          @click="
            () => {
              if (valueFilter == '') valueFilter = item.value;
              else valueFilter = '';
            }
          "
          :color="getValueColor(item.value)"
        >
          {{ getLabel(item.value).toUpperCase() }}
        </v-chip>
      </template>
      <!-- <template v-slot:[`item.total`]="{ item }">
        <span>
          {{ item.total }}
        </span>
        <span class="text-caption">
          {{ " (" + percentage(item.total) + ")" }}
        </span>
      </template> -->
      <template slot="body.append">
        <tr>
          <td colspan="6"></td>
          <td class="font-weight-bold">{{ total }}</td>
          <td
            v-for="context in contextFilterList"
            :key="context"
            class="font-weight-bold"
          >
            {{ contextTotals[context] }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { getItemFieldLabel } from "../../scripts/data/tableData";
import stages from "../../assets/stages";
const Papa = require("papaparse");
export default {
  props: ["stats"],
  data: () => ({
    stageFilter: "",
    pillarFilter: "",
    dimensionFilter: "",
    valueFilter: "",
    contextFilterList: [],
    filteredItems: [],
  }),
  computed: {
    headers: function() {
      let header = [
        { text: "Pergunta", value: "question" },
        { text: "Resposta", value: "label" },
        {
          text: "Pilar",
          value: "pillar",
          filter: (value) =>
            this.pillarFilter == null || value.includes(this.pillarFilter),
        },
        {
          text: "Estágio",
          value: "stage",
          filter: (value) =>
            this.stageFilter == null || value.includes(this.stageFilter),
        },
        {
          text: "Dimensão",
          value: "dimension",
          filter: (value) =>
            this.dimensionFilter == null ||
            value.includes(this.dimensionFilter),
        },
        {
          text: "Valor",
          value: "value",
          filter: (value) =>
            this.valueFilter == null || value.includes(this.valueFilter),
        },
        { text: "Total", value: "total" },
      ];
      this.contextFilterList.forEach((context) => {
        header.push({
          text: context.toUpperCase(),
          value: context,
        });
      });

      return header;
    },
    items: function() {
      let items = Object.values(this.stats).sort(function(a, b) {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
      items = items.map((item) => {
        return { ...item, ...item.context };
      });
      return items;
    },
    formatedData: function() {
      return this.items.map((el) => ({
        ...el,
        question: stages[el.stage].label,
      }));
    },
    total: function() {
      return this.filteredItems.reduce((a, b) => a + (b["total"] || 0), 0);
    },
    contextTotals() {
      let totals = {};
      this.contextFilterList.forEach((context) => {
        totals[context] = this.filteredItems.reduce(
          (a, b) => a + (b["context"][context] || 0),
          0
        );
      });
      return totals;
    },
  },
  methods: {
    getPillarColor(value) {
      switch (value) {
        case "confianca":
          return "purple darken-1";
        case "cuidado":
          return "pink darken-1";
        case "expressao":
          return "indigo darken-1";
        default:
          return "grey";
      }
    },
    getValueColor(value) {
      if (value.toLowerCase() == "positivo") return "success";
      else return "error";
    },
    percentage(value) {
      return ((value / this.total) * 100).toFixed(2) + "%";
    },
    getLabel(key) {
      return getItemFieldLabel(key);
    },
    getFiltered(list) {
      this.filteredItems = list;
    },
    async exportAndDownload() {
      var formatedData = {
        fields: [],
        data: this.filteredItems,
      };
      this.headers.forEach((header) => {
        formatedData.fields.push(header.value);
      });

      var csv = Papa.unparse(formatedData);

      this.headers.forEach((header) => {
        csv = csv.replace(header.value, header.text);
      });

      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], {
        type: "text/csv",
      });

      downloadLink = document.createElement("a");

      var fileName =
        new Date(Date.now())
          .toLocaleString()
          .split(",")[0]
          .replace("/", "_")
          .replace(" ", "_") + ".csv";
      fileName = `${fileName}`;

      downloadLink.download = fileName;

      downloadLink.href = window.URL.createObjectURL(csvFile);

      downloadLink.style.display = "none";

      document.body.appendChild(downloadLink);

      downloadLink.click();
    },
  },
  mounted() {
    this.filteredItems = this.items;
    this.contextFilterList = [];
    this.items.forEach((choice) => {
      Object.keys(choice.context).forEach((key) => {
        if (this.contextFilterList.indexOf(key) < 0)
          this.contextFilterList.push(key);
      });
    });
  },
};
</script>
<style scoped>
.treeview-item {
  font-size: 0.6rem;
}

@media only screen and (max-width: 600px) {
  .row {
    margin: 0;
  }
  .export-btn-container {
    justify-content: start !important;
  }
}
</style>
