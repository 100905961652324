import Vue from "vue";

const requireContext = require.context("../components/global", true, /\.vue$/);
requireContext.keys().forEach((key) => {
    let name = requireContext(key).default.name
    let component = import("../components/global" + key.replace("./", "/"));
    Vue.component(name, () => component);
});

import { VBtn, VRow, VCol, VContainer, VCarousel, VCarouselItem, VSheet, VIcon } from 'vuetify/lib'
Vue.component('v-btn', VBtn);
Vue.component('v-row', VRow);
Vue.component('v-col', VCol);
Vue.component('v-container', VContainer);
Vue.component('v-carousel', VCarousel);
Vue.component('v-carousel-item', VCarouselItem);
Vue.component('v-sheet', VSheet);
Vue.component('v-icon', VIcon);