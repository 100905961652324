<template>
  <v-container fluid>
    <v-row justify="end" class="mb-4">
      <v-col cols="2">
        <v-btn block @click="exportAndDownload">Exportar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :items="comments"
          :headers="headers"
          :items-per-page="-1"
          :hide-default-footer="true"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const Papa = require("papaparse");
export default {
  props: ["comments"],
  data: () => ({ headers: [{ text: "comment", value: "comment" }] }),
  methods: {
    async exportAndDownload() {
      var formatedData = {
        fields: ["comment"],
        data: this.comments,
      };
      var csv = Papa.unparse(formatedData);

      csv = csv.replace("comment", "Comentário");

      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], {
        type: "text/csv",
      });

      downloadLink = document.createElement("a");

      var fileName =
        new Date(Date.now())
          .toLocaleString()
          .split(",")[0]
          .replace("/", "_")
          .replace(" ", "_") + ".csv";
      fileName = `${fileName}`;

      downloadLink.download = fileName;

      downloadLink.href = window.URL.createObjectURL(csvFile);

      downloadLink.style.display = "none";

      document.body.appendChild(downloadLink);

      downloadLink.click();
    },
  },
};
</script>