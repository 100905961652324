<template>
  <v-overflow-btn
    v-model="value"
    :items="field.items"
    :label="field.label"
    :error-messages="errorMessages"
    @change="onValueUpdate"
  ></v-overflow-btn>
</template>
<script>
import { setCurrentTrackData } from "../../scripts/data/track";
import store from "../../scripts/store";
import { rules } from "../../scripts/formRules";
export default {
  name: "select-field",
  props: ["field"],
  data: () => ({
    value: null,
    valid: false,
    errorMessages: [],
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var storedData = store.getters.currentTrackRecord?.data[this.field.prop];
      if (storedData) this.value = storedData;
      this.onValueUpdate();
    },
    validate() {
      var isValid = true;
      this.errorMessages = [];
      if (this.field.rules) {
        this.field.rules.forEach((rule) => {
          var valid = rules[rule.type](this.value);
          if (!valid) {
            isValid = valid;
            this.errorMessages.push(rule.message);
          }
        });
      }
      this.valid = isValid;
    },
    onValueUpdate() {
      this.validate();
      setCurrentTrackData({
        prop: this.field.prop,
        value: this.value,
        valid: this.valid,
      });
      this.$emit("on-event", { type: "onValueUpdate" });
    },
  },
};
</script>
