<template>
  <div class="tutorial-container enter-animation-target leave-animation-target">
    <draggable-tutorial-mobile v-if="$vuetify.breakpoint.mobile" />
    <draggable-tutorial-desktop v-else />
  </div>
</template>
<script>
import DraggableTutorialDesktop from "../draggable/DraggableTutorialDesktop.vue";
import DraggableTutorialMobile from "../draggable/DraggableTutorialMobile.vue";
export default {
  components: { DraggableTutorialDesktop, DraggableTutorialMobile },
  name: "draggable-tutorial",
};
</script>
<style scoped>
.tutorial-container {
  width: 100%;
  height: 100%;
}
</style>
