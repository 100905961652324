import { getItemFieldLabel } from "../scripts/data/tableData";
import stages from "../assets/stages.json"
export var statMixin = {

    methods: {
        getQuestionLabel(key) {
            return stages[key].label
        },
        getLabel(key) {
            return getItemFieldLabel(key);
        },
        percentage(value, total) {
            return ((value / total) * 100).toFixed(2) + "%";
        },
    }
}