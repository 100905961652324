<template>
  <v-tabs vertical v-model="tab">
    <v-tab v-for="formatedStat in formatedStats" :key="formatedStat.key">
      {{ formatedStat.key.replace("_", " ") }}
    </v-tab>
    <v-tab-item v-for="(stat, i) in formatedStats" :key="`stat-${i}`">
      <v-container>
        <v-row>
          <v-col
            v-for="(value, key) in stat.value"
            :key="`stat-${stat.key}-${key}`"
            cols="4"
          >
            <v-card
              class="mx-auto"
              elevation="4"
              color="secondary"
              style="height: 100%"
            >
              <v-container>
                <v-card-title>{{ `${getLabel(value[0].stage)}` }}</v-card-title>
                <v-card-subtitle>
                  {{ `${getQuestionLabel(value[0].stage)}` }}
                </v-card-subtitle>
                <v-list-item three-line v-for="item in value" :key="item.id">
                  <v-list-item-icon
                    ><v-icon
                      v-if="item.value === 'positivo'"
                      color="success"
                      class="mr-2"
                      >mdi-plus-circle</v-icon
                    >
                    <v-icon v-else color="error" class="mr-2"
                      >mdi-minus-circle</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ `${percentage(item.total, item.absoluteTotal)}` }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.label }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
  </v-tabs>
</template>
  <script>
import { statMixin } from "../../mixins/statUtil";
export default {
  props: ["stats", "general"],
  data: () => ({
    tab: null,
  }),
  mixins: [statMixin],
  computed: {
    formatedStats: function () {
      let formatedStats = [];

      let formatedStat = { key: "geral", value: {} };
      Object.keys(this.stats).forEach((key) => {
        let stat = this.stats[key];
        if (!formatedStat.value[`${stat.pillar}_${stat.stage}`])
          formatedStat.value[`${stat.pillar}_${stat.stage}`] = [];
        formatedStat.value[`${stat.pillar}_${stat.stage}`].push(stat);
      });
      Object.keys(formatedStat.value).forEach((key) => {
        let total = this.general.totalUsers;
        formatedStat.value[key] = formatedStat.value[key].map((stat) => ({
          ...stat,
          absoluteTotal: total,
        }));
      });
      Object.keys(formatedStat.value).forEach((key) => {
        formatedStat.value[key] = formatedStat.value[key].sort(this.sortStat);
      });
      formatedStats.push(formatedStat);

      let contextKeys = [];
      Object.keys(this.stats).forEach((key) => {
        let stat = this.stats[key];
        Object.keys(stat.context).forEach((ctxKey) => {
          if (contextKeys.indexOf(ctxKey) < 0) contextKeys.push(ctxKey);
        });
      });

      contextKeys.forEach((ctxKey) => {
        let ctxObj = { key: ctxKey, value: {} };
        Object.keys(this.stats).forEach((key) => {
          let stat = this.stats[key];
          if (!ctxObj.value[`${stat.pillar}_${stat.stage}`])
            ctxObj.value[`${stat.pillar}_${stat.stage}`] = [];
          ctxObj.value[`${stat.pillar}_${stat.stage}`].push(stat);
        });
        Object.keys(ctxObj.value).forEach((key) => {
          let total = this.general[ctxKey];
          ctxObj.value[key] = ctxObj.value[key].map((stat) => ({
            ...stat,
            total: stat.context[ctxKey] ? stat.context[ctxKey] : 0,
            absoluteTotal: total,
          }));
        });
        Object.keys(ctxObj.value).forEach((key) => {
          ctxObj.value[key] = ctxObj.value[key].sort(this.sortStat);
        });
        formatedStats.push(ctxObj);
      });

      return formatedStats;
    },
  },
  methods: {
    sortStat(a, b) {
      if (a.total < b.total) {
        return 1;
      }
      if (a.total > b.total) {
        return -1;
      }
      return 0;
    },
  },
};
</script>