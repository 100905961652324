<template>
  <div
    :id="item.id"
    :style="{
      webkitTransform: `translate(${item.x}px, ${item.y}px)`,
      transform: `translate(${item.x}px, ${item.y}px)`,
    }"
    :class="[
      'draggable',
      'draggable-card',
      $vuetify.breakpoint.mobile ? 'mobile' : 'desktop',
      dragging ? 'dragging' : 'idle',
      'enter-animation-target',
      'leave-animation-target',
    ]"
  >
    {{ item.label }}
  </div>
</template>
<script>
export default {
  props: ["item", "dragging"],
};
</script>
<style lang="scss" scoped>
.draggable {
  -ms-touch-action: none;
  touch-action: none;
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
  cursor: grab;
  transition: transform 200ms, margin 200ms;
}
.draggable-card {
  background: var(--v-secondary-base);
  color: var(--v-header-base);
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 2px 2px 10px rgba(black, 0.2);

  height: 100%;
  width: 100%;
  max-height: 300px;
  max-width: 300px;
}
.draggable-card.mobile {
  font-size: 0.9rem;
}
.draggable-card.desktop {
  font-size: 1.2rem;
}
.draggable-card.dragging {
  transition: transform 0s;
  z-index: 99;
  box-shadow: 4x 2px 16px rgba(black, 0.2);
}
</style>