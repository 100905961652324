import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: {
                    base: "#FF1E4E",
                    lighten1: "#FF4B72",
                    lighten2: "#FF7B97",
                    lighten3: "#FCCDDB",
                },
                secondary: '#FAF7DD',
                'header': "#707070",
                'body': "#A6A6A6"
            }
        },
        options: {
            customProperties: true
        },
    },
});

