<template>
  <div>
    <div>
      <v-progress-linear
        v-if="trackControl.progress"
        :height="$vuetify.breakpoint.mobile ? '10px' : '20px'"
        :value="
          (trackControl.progress.current / trackControl.progress.total) * 100
        "
        background-color="black"
        background-opacity="0.1"
      ></v-progress-linear>
    </div>
    <v-container py-4 px-10>
      <v-row
        v-if="$vuetify.breakpoint.mobile"
        align="center"
        justify="space-between"
        style="min-height: 50px"
      >
        <node-step
          v-if="trackControl.previous"
          :step="{
            ...trackControl.previous,
            is: 'v-btn',
            options: {
              color: 'grey',
              dark: true,
              elevation: 0,
              'x-small': true,
              fab: true,
              disabled: disablePreviousStep,
            },
          }"
          @on-event="onEvent"
        >
          <v-icon x-large>mdi-chevron-left</v-icon>
        </node-step>
        <v-col>
          <animation>
            <h1
              v-if="trackControl.title"
              :key="trackControl.title"
              class="
                text-h6
                header--text
                enter-animation-target
                leave-animation-target
              "
              style="line-height: 1"
            >
              {{ trackControl.title }}
            </h1>
          </animation>
        </v-col>
        <node-step
          v-if="trackControl.next"
          :step="{
            ...trackControl.next,
            is: 'v-btn',
            options: {
              color: 'primary',
              elevation: 0,
              'x-small': true,
              fab: true,
              disabled: disableNextStep,
            },
          }"
          @on-event="onEvent"
        >
          <v-icon x-large>mdi-chevron-right</v-icon>
        </node-step>
      </v-row>
      <v-row v-else align="center" style="min-height: 100px">
        <v-col>
          <animation>
            <h5
              v-if="trackControl.subtitle"
              :key="trackControl.subtitle"
              class="
                text-h7
                primary--text
                text-uppercase
                font-weight-bold
                enter-animation-target
                leave-animation-target
              "
            >
              {{ trackControl.subtitle }}
            </h5>
          </animation>
          <animation>
            <h1
              v-if="trackControl.title"
              :key="trackControl.title"
              class="text-h5 header--text"
            >
              {{ trackControl.title }}
            </h1>
          </animation>
        </v-col>
        <div class="mr-8">
          <node-step
            v-if="trackControl.previous"
            :step="{
              ...trackControl.previous,
              is: 'v-btn',
              options: {
                color: 'grey',
                dark: true,
                elevation: 0,
                small: true,
                fab: true,
                disabled: disablePreviousStep,
              },
            }"
            @on-event="onEvent"
          >
            <v-icon size="52">mdi-chevron-left</v-icon>
          </node-step>
        </div>
        <div>
          <node-step
            v-if="trackControl.next"
            :step="{
              ...trackControl.next,
              is: 'v-btn',
              options: {
                color: 'primary',
                elevation: 0,
                small: true,
                fab: true,
                disabled: disableNextStep,
              },
            }"
            @on-event="onEvent"
          >
            <v-icon size="52">mdi-chevron-right</v-icon>
          </node-step>
        </div>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "track-control",
  props: {
    trackControl: { default: null, type: Object },
    disableNextStep: { default: false, type: Boolean },
    disablePreviousStep: { default: false, type: Boolean },
  },
  methods: {
    onEvent(event) {
      this.$emit("on-event", event);
    },
  },
};
</script>