import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import NotFound from "../views/NotFound";
import Auth from "../views/Auth";
import Track from "../views/Track";
import Stats from "../views/Stats";
import Admin from "../views/Admin";

Vue.use(Router);

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/trilha/:id",
    name: "Track",
    component: Track,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/termometro/:id",
    name: "Stats",
    component: Stats,
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const hideForAuth = to.matched.some((record) => record.meta.hideForAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const loggedIn = store.getters.loggedIn;

  if (loggedIn === false && requiresAuth === true) {
    return next({ path: "/auth", query: { redirect: to.path } });
  }

  let roles = store.getters.user.roles;
  let admin = false;
  if (roles != null && roles["admin"] != null && roles["admin"].active === true)
    admin = true;

  if (requiresAdmin === true && admin === false) {
    return next({ path: "/auth", query: { redirect: to.path } });
  }

  if (hideForAuth === true && loggedIn === true) {
    return next({ path: "/" });
  }

  return next();
});

export default router;
