

export function getItemFieldLabel(key) {
    if (Array.isArray(key) || typeof item === 'object' || !fieldLabels[key]) return key;
    return fieldLabels[key];
}

const fieldLabels = {
    'positivo': "Positivo",
    'negativo': "Negativo",
    'aparente': "Aparente",
    'social': "Social",
    'emocional': "Emocional",
    'cuidado': "Cuidado",
    'confianca': "Confiança",
    'expressao': "Expressão",
    'cultivo': "Cultivo",
    'conhecimento_pratico': "Conhecimento Prático",
    'criatividade': "Criatividade",
    'abertura': "Abertura",
    'desafio': "Desafio",
    'autonomia': "Autonomia",
    'colaboracao': "Colaboração",
    'crescimento': "Crescimento",
    'plenitude': "Plenitude",
    'totalChoices': "Todas Escolhas",
    'totalPositiveChoices': "Todas Escolhas Positivas",
    'totalNegativeChoices': "Todas Escolhas Negativas",
    "total": "Total",
    "totalUsers": "Total de usuários",
    "departamento": "Departamento",
    "cargo": "Cargo",
    "rh": "Recusros Humanos",
    "lideranca": "Liderança",
    "stage": "Estágio",
    "pillar": "Pilar",
    "dimension": "Dimensão",
    "value": "Valor"
}
