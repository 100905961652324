<template>
  <div px-2>
    <!-- <select-field-from-field-set
      v-for="(fieldValue, fieldProp) in fieldSets"
      :key="`${prop}-${fieldProp}`"
      :field="{
        prop: fieldValue.prop,
        label: fieldValue.label,
        isContext: true,
        rules: [
          {
            type: 'required',
            message: 'Campo obrigatório',
          },
        ],
      }"
      class="enter-animation-target leave-animation-target"
    ></select-field-from-field-set> -->
    <div
      v-for="(fieldValue, fieldProp) in fieldSets"
      :key="`${prop}-${fieldProp}`"
      class="mx-1 enter-animation-target leave-animation-target"
    >
      <select-field-from-field-set
        :field="{
          prop: fieldProp,
          label: getText(fieldProp),
          isContext: true,
          rules: [
            {
              type: 'required',
              message: 'Campo obrigatório',
            },
          ],
        }"
      ></select-field-from-field-set>
    </div>
  </div>
</template>
<script>
import { trackRequestData } from "../../scripts/data/track";
export default {
  name: "select-group-from-field-set",
  computed: {
    fieldSets: function() {
      return trackRequestData["fieldSets"];
    },
  },
  methods: {
    getText(prop) {
      if (trackRequestData["data"] && trackRequestData["data"][prop])
        return trackRequestData["data"][prop];
      else return prop;
    },
  },
};
</script>
