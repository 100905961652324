var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"px-2":""}},_vm._l((_vm.fieldSets),function(fieldValue,fieldProp){return _c('div',{key:(_vm.prop + "-" + fieldProp),staticClass:"mx-1 enter-animation-target leave-animation-target"},[_c('select-field-from-field-set',{attrs:{"field":{
        prop: fieldProp,
        label: _vm.getText(fieldProp),
        isContext: true,
        rules: [
          {
            type: 'required',
            message: 'Campo obrigatório',
          } ],
      }}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }